import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

const formaInvtype = val => {
  if (val === 1) {
    return '增值税发票';
  } else if (val === 2) {
    return '普通发票';
  }
};
const formaType = val => {
  if (val === 1) {
    return '开净重';
  } else if (val === 2) {
    return '开数量';
  }
};
export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '单据号', prop: 'tinv_no', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  {
    label: '开票状态',
    prop: 'pinv_invstat',
    itemType: 'select',
    options: [
      { value: 0, label: '可开票' },
      { value: 1, label: '已开票' },
      { value: 2, label: '部分开票' }
    ],
    input: true,
    sortable: false,
    widthAuto: false,
    formatter: val => (val === 0 ? '可开票' : '已开票')
  },
  { label: '发票号码', prop: 'pinv_actu_no', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '源发票号码', prop: 'pinv_actu_nos', itemType: 'input', input: true, sortable: false, widthAuto: false },
  {
    label: '发票日期',
    prop: 'pinv_date',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },
  {
    label: '发票种类',
    prop: 'tinv_invtype',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => formaInvtype(val)
  },
  { label: '发票金额', prop: 'pinv_part_subtotal', itemType: 'input', input: false, sortable: false, widthAuto: false },
  {
    label: '收票日期',
    prop: 'pinv_recdate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '转开票金额', prop: 'tinv_invtotal', itemType: 'input', input: false, sortable: false, widthAuto: false },
  {
    label: '转开票类型',
    prop: 'tinv_type',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    formatter: val => formaType(val)
  },
  { label: '转开报关品名', prop: 'prod_hsname', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '总净重', prop: 'prod_tnweight', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '含净重数量', prop: 'pinv_num', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '开票数量单位', prop: 'pinv_part_unit', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '海关编码', prop: 'prod_hscode', itemType: 'input', input: false, sortable: false, widthAuto: false },
  { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, widthAuto: false },
  { label: '转开抬头', prop: 'cptt_bname', itemType: 'input', input: false, sortable: false, labelWidth: 160, overflowTooltip: true },
  store.state.stff_name,
  store.state.create_time,
  store.state.status
];
export const dialogTableProperties = [
  { label: '单据号', prop: 'tinv_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '转开抬头', prop: 'cptt_bname', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '转开票金额', prop: 'tinv_invtotal', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
