<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增 </el-button>
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-date-picker
          class="vg_ml_8 vg_mr_8 topDateRangeSearch"
          size="small"
          v-model="dateRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
        <el-button type="primary" size="small" icon="el-icon-search" @click="getTinvsList()"> 查询</el-button>
        <el-button type="info" size="small" class="vd_export" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
        <el-button class="vd_export" size="small" type="primary" @click="syncDialogVisible = true">同步老ERP</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'tinv_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getTinvsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
      <el-dialog :visible.sync="syncDialogVisible" title="同步老ERP" top="0" width="70%">
        <div class="flexHV">
          <span style="margin-left: auto">注意:搜索为精确搜索，搜索过后才显示内容 同步数据将自动生效不可更改</span>
        </div>
        <DynamicUTable
          ref="dialogTable"
          :columns="dialogTableProperties"
          :dbClickJp="dialogDbClick"
          :need-check-box="false"
          :need-search="true"
          :needPagination="false"
          :table-data="dialogTableData"
          @getTableData="getSyncTableData"
        ></DynamicUTable>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, post } from '@api/request';
import { tinvAPI } from '@api/modules/tinv';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { dialogTableProperties, tableProperties } from '@/views/FinanceManagement/TinvManage/tinv';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';
import { setTime } from '@api/public';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'TinvList',
  components: {
    DynamicUTable,
    SearchTable
  },
  data() {
    return {
      dateRange: [],
      tableProperties: tableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      syncDialogVisible: false,
      dialogTableData: [],
      dialogTableProperties: dialogTableProperties
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/tinv_edit' || from.path === '/tinv_add') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getTinvsList();
    },
    async getTinvsList() {
      await (this.loadFlag = true);
      let searchForm = this.$refs.multiTable.searchForm;
      setTime(searchForm, 'start_time', 'end_time', this.dateRange);
      getNoCatch(tinvAPI.getTinvs, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/tinv_add?perm_id=${permId}`);
    },
    dbClickJp(row) {
      this.jump('/tinv_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.tinv_id
          })
        )
      });
    },
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.tinv_id);
          });
          post(tinvAPI.deleteTinvByIds, { tinv_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(err => console.error(err));
    },
    refresh() {
      this.dateRange = [];
      this.$refs.multiTable.resetFields();
      this.getTinvsList();
    },
    getSyncTableData() {
      let deep = cloneDeep(this.$refs.dialogTable.searchForm);
      delete deep.page_no;
      if (Object.values(deep).every(value => !value)) return;
      tinvAPI.getTinvListByRichMax(this.$refs.dialogTable.searchForm).then(({ data }) => {
        this.dialogTableData = data.list;
      });
    },
    dialogDbClick(row) {
      this.$confirm('确定同步？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          tinvAPI.addTinvByRichMax(row).then(() => {
            this.$message.success('同步成功!');
            this.syncDialogVisible = false;
            this.$refs.multiTable.searchForm.tinv_no = row.tinv_no;
            this.getTinvsList();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
