import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';

export const tinvAPI = {
  getTinvs: topic.tinvTopic + '/get_tinvs',
  getTinvById: topic.tinvTopic + '/get_tinv_by_id',
  addTinv: topic.tinvTopic + '/add_tinv',
  editTinv: topic.tinvTopic + '/edit_tinv',
  deleteTinvByIds: topic.tinvTopic + '/delete_tinv_by_ids',
  getAllTinvsV1: topic.tinvTopic + '/get_all_tinvs_v1',
  get_tinvPartByTranNo: topic.tinvTopic + '/get_tinvPartByTranNo',
  exportExcel: params => getNoCatch(topic.tinvTopic + '/exportTinvById', params),
  getTinvListByRichMax: params => getNoCatch(topic.tinvTopic + '/getTinvListByRichMax', params),
  addTinvByRichMax: params => postNoCatch(topic.tinvTopic + '/addTinvByRichMax', params)
};
